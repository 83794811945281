import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Privatdarlehen = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Privatdarlehen – Die Vor- und Nachteile" showCalc={false} />
            <Article>
                <p>
                    Angesichts aktueller globaler Krisen suchen zahlreiche Menschen nach alternativen Möglichkeiten, um
                    Immobilien zu finanzieren. Eine davon ist das Privatdarlehen. Im folgenden Artikel erklären wir dir,
                    was darunter zu verstehen ist, und diskutieren jeweils 5 Vor- und Nachteile dieses
                    Finanzierungsmodells.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was ist ein Darlehen?</h2>
                <p>
                    Unter Darlehen versteht man den Verleih von Kapital zu Finanzierungszwecken. Die entsprechende Summe
                    muss in einem vertraglich festgelegten Zeitraum zurückgezahlt werden. Zentral ist hierbei, dass der
                    Darlehensvertrag erst dann zustandekommt, wenn der jeweilige Betrag an den:die Kreditnehmer:in
                    ausgezahlt wurde. Das unterscheidet Darlehen von Krediten. Auch wenn die beiden Begriffe in der
                    Alltagssprache oft synonym verwendet werden, gilt: Das Darlehen ist eine spezielle Form des{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>
                    , bei der besonders hohe Kapitalsummen verliehen werden und die eine vergleichsweise lange Laufzeit
                    aufweist. Im Unterschied zu Krediten, die in Österreich eine Laufzeit von maximal 4 Jahren haben,
                    können Darlehen über einen Zeitraum von 10 bis 40 Jahren zurückgezahlt werden. Falls du dich für die
                    wechselseitigen Auswirkungen von Laufzeit, Kreditsumme und Zinssatz interessierst, kannst du unseren{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    zu Rate ziehen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Die Vor- und Nachteile eines Privatdarlehens</h2>
                <p>
                    Wenn du dir größere Geldsummen von Verwandten, Freund*innen oder sonstigen Privatpersonen aus deinem
                    Umfeld leihst, handelt es sich bei dieser Transaktion um ein Darlehen – genauer um ein
                    Privatdarlehen: Vetragspartner:innen sind hier zwei natürliche Personen. Zwar besteht zwischen
                    diesen in den meisten Fällen ein Vertrauensverhältnis. Um Rechtsstreitigkeiten zu vermeiden,
                    empfiehlt es sich allerdings auch hier, einen Darlehensvertrag zu erstellen.
                </p>
                <h3>Vorteile</h3>
                <ul>
                    <li>
                        <strong>
                            Keine Bindung an die{" "}
                            <Link to="/artikel/kreditregeln/" target="_blank" rel="noreferrer noopener">
                                neuen Kreditregeln
                            </Link>
                            :
                        </strong>{" "}
                        Wie du vielleicht schon weißt, gelten seit August 2022 auch in Österreich neue Richtlinien bei
                        der Vergabe von Immobilienkrediten. Diese sehen unter anderem vor, dass ausschließlich Personen,
                        die mindestens 20 % der Finanzierungskosten mit Eigenkapital bestreiten können, einen Kredit
                        erhalten dürfen. Mit einem Privatkredit bist du nicht an derartige Richtlinien gebunden.
                    </li>
                    <li>
                        <strong>Nahverhältnis zu den Kreditgebenden:</strong> Sollte es bei der Rückzahlung der
                        geliehenen Summe zu Schwierigkeiten wie dem Ausfall von Raten oder dgl. kommen, so ist es oft
                        erheblich einfacher, diese mit Personen zu besprechen, zu denen man ohnehin ein
                        Vertrauensverhältnis pflegt. Im Idealfall kann man gemeinsam eine Lösung erarbeiten. Diese fällt
                        im privaten Umfeld zudem oft flexibler aus, als es bei Kreditinstituten der Fall ist.
                    </li>
                    <li>
                        <strong>rasche Abwicklung:</strong> Im Gegensatz zu Krediten, die von einer Bank gewährt werden,
                        musst du im Privatbereich nicht mit langen Bearbeitungszeiten oder frustrierendem paperwork
                        rechnen, sondern kommst rasch und unkompliziert an die gewünschte Summe. Gerade bei dringenden
                        Zahlungen aufgrund von Schäden sparst du damit wertvolle Zeit. Weil keine Bearbeitungsgebühren
                        anfallen, sparst du auch noch bares Geld.
                    </li>
                    <li>
                        <strong>niedrigere oder keine Zinsen:</strong> Geld sparst du üblicherweise auch bei den{" "}
                        <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                            Zinsen
                        </Link>
                        . Während sich Kreditinstitute am Leitzins der Europäischen Zentralbank orientieren und mit
                        diesen steigen oder sinken, entfällt diese Abhängigkeit vom Finanzmarkt im Falle eines
                        Privatdarlehens.
                    </li>
                    <li>
                        <strong>keine Verringerung der Bonität durch einen laufenden Kredit:</strong> Ein weiterer
                        Pluspunkt von Privatdarlehen ist der Umstand, dass sie im Falle einer Bonitätsprüfung nicht
                        berücksichtigt werden. Solltest du einen weiteren Kredit bei einer Bank aufnehmen, wirkt sich
                        das privat gewährte Darlehen also nicht negativ auf deine Chancen aus.
                    </li>
                </ul>
                <h3>Nachteile</h3>
                <ul>
                    <li>
                        <strong>Nahverhältnis zu den Kreditgebenden:</strong> Was zunächst ein Vorteil ist, kann rasch
                        zum Nachteil werden: Nicht alle Freundschafts- oder Verwandtschaftsbeziehungen halten die
                        Belastung durch ein Schuldverhältnis aus. Überlege dir also gut, ob und von wem du dir im
                        privaten Umfeld Geld leihst. Wir empfehlen dir zudem, auf Nummer sicher zu gehen und auch für
                        ein Privatdarlehen einen Kreditvertrag aufsetzen zu lassen. Das kostet zwar, erleichtert im
                        Streitfall das Vorgehen allerdings erheblich.
                    </li>
                    <li>
                        <strong>Kein formaler Schutz:</strong> Im Gegensatz zu einem offiziellen Kredit, bei dem du als
                        Schuldner:in durch klar definierte Rechte geschützt bist, entfällt diese Sicherheit bei einem
                        Privatdarlehen. Auch deswegen empfiehlt es sich, einen Kreditvertrag zu erstellen und dem
                        Schuldverhältnis auf diese Weise einen formal verbindlichen Rahmen zu geben.
                    </li>
                    <li>
                        <strong>Keine Bonitätsprüfung:</strong> Auch der Umstand, dass im Falle eines Privatdarlehens
                        die Bonitätsprüfung entfällt, muss nicht unbedingt ein Vorteil sein. Diese ist zumindest in
                        einigen Fällen ein Mechanismus, der Personen vor der Überschuldung schützt.
                    </li>
                    <li>
                        <strong>Keine professionelle Ansprechparnter:in:</strong> Während du dir mit dem Verzicht auf
                        die Finanzierung mit einem Kreditinstitut einerseits Zeit und Geld sparst, verzichtest du
                        andererseits auch auf ein umfassendes Beratungsangebot. Gerade, wenn es um die langfristige
                        Planung von Investitionen geht, ist professionelle Beratung unerlässlich. Wir bei miracl helfen
                        dir gerne weiter!
                    </li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>

            <BreadcrumbList page={"privatdarlehen"}></BreadcrumbList>
            <ArticleStructuredData page={"privatdarlehen"} heading={"Privatdarlehen – Die Vor- und Nachteile"} />
        </Layout>
    );
};

export default Privatdarlehen;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "page.artikel.privatdarlehen"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
